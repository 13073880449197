<template>
  <v-container fluid>
  
    <v-row class="mt-2">        
      <v-col cols="12">
        <v-card dark outlined>
          
          <v-card>
            <v-card-title class="text-h6">
              Device Management
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <v-form 
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                    
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="eventItem.DeviceId"
                      label="Device ID"
                      :rules="[v => !!v || 'Device ID is required']"
                    ></v-text-field>
                  </v-col>
                </v-row>                        
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="eventItem.MerchantId"
                      label="Merchant ID"                      
                      type="number"
                      :rules="[v => !!v || 'Merchant ID is required']"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>                  
                  <v-col cols="12">
                    <v-combobox 
                        v-model="eventItem.Authorised"
                        :items="authorisedTypes"
                        item-text="name"
                        :return-object="false"
                        label="Device Authorised"
                        required
                    ></v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <!-- <br> -->
                    <!-- <hr color = orange> -->
                    <br>
                    <p> If you choose to enter any of the below fields, you must complete all of them:</p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        :items="allStallHolders"
                        v-model="selectedStallholder"
                        item-text="StallholderName"
                        return-object
                        filled
                        dense
                        outlined
                        hide-details
                        label="Stallholder"
                        @change="changeStallholder"
                    >
                        <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-title>
                            <v-icon small color="red lighten-1">
                              mdi-information
                            </v-icon>
                              No Stallholders found
                            </v-list-item-title>
                        </v-list-item>
                        </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        :items="stalls"
                        v-model="selectedStall"
                        item-text="StallName"
                        return-object
                        filled
                        dense
                        outlined
                        hide-details
                        label="Stall"
                        @change="changeStall"
                    >
                        <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-title>
                            <v-icon small color="red lighten-1">
                              mdi-information
                            </v-icon>
                              No Stalls found
                            </v-list-item-title>
                        </v-list-item>
                        </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        :items="terminals"
                        v-model="selectedTerminal"
                        item-text="TerminalNumber"
                        return-object
                        filled
                        dense
                        outlined
                        hide-details
                        label="Terminal"
                        >
                        <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-title>
                            <v-icon small color="red lighten-1">
                              mdi-information
                            </v-icon>
                              No Terminals found
                            </v-list-item-title>
                        </v-list-item>
                        </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                        :items="terminalUsers"
                        v-model="selectedUser"
                        item-text="TerminalUserName"
                        return-object
                        filled
                        dense
                        outlined
                        hide-details
                        label="User"
                    >
                        <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-title>
                            <v-icon small color="red lighten-1">
                              mdi-information
                            </v-icon>
                              No event found
                            </v-list-item-title>
                        </v-list-item>
                        </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="clickCancel"
              >Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >Save
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>


<script>
    import 'izitoast/dist/css/iziToast.min.css';
    import iziToast from 'izitoast/dist/js/iziToast.min.js';
    import {
      mapState,
      mapActions
    } from 'vuex'

  export default {
    data: () => ({
      
      eventItem: {
        Authorised: 'Yes'
      },
      
      editedItem: {
        Stallholder: '',
        Stall: '',
        Terminal: '',
      },
      
      Stallholder: {},

      selectedStallholder: {},
      selectedStall: {},
      selectedTerminal: {},
      selectedUser: '',

      stalls: [],
      terminals: [],
      terminalUsers: [],
      
      authorisedTypes: [
          { id: 'Yes', name: 'Yes'},
          { id: 'No', name: 'No'}
          ],
      
      valid: true,

    }
  ),

  computed: {        
    ...mapState('filter', ['globalEventFilter', 'allStallHolders', 'allTerminalUsers']),
  },

  created() {
    if (this.$route.params.id) {

        this.Action = 'update';

        let eventObj = localStorage.getItem('setDeviceObj');
        let eventItem = JSON.parse(eventObj);
        
        this.eventItem.MerchantId = eventItem.MerchantId
        this.eventItem.DeviceId = eventItem.DeviceId
        this.eventItem.Authorised = eventItem.Authorised
              
        this.selectedStallholder = eventItem

        this.stalls = this.allStallHolders.filter(element => {
            return element.StallholderId === eventItem.StallholderId;
          })[0].Stalls;
        this.selectedStall = eventItem

        this.terminals = this.stalls.filter(element => {
            return element.StallId === eventItem.StallId;
          })[0].Terminals;
        this.selectedTerminal = this.terminals.filter(element => {
          return element.TerminalId === eventItem.TerminalId
        })[0]

        this.terminalUsers = this.allTerminalUsers.filter(element => {
          return element.StallholderId === this.selectedStallholder.StallholderId;
        });
        this.selectedUser = this.terminalUsers.filter(element => {
          return element.TerminalUserId === eventItem.TerminalUserId
        })[0]
        
      } else {
        this.Action = 'create';
    }
  },
  
  watch: {
    Stallholder: function(val) {

      this.stalls = this.allStallHolders.filter(element => {
        return element.StallholderId === val.StallholderId;
        })[0].Stalls;    
      
      this.terminalUsers = this.allTerminalUsers.filter(element => {
        return element.StallholderId === val.StallholderId;
        });
        
      },

    selectedStall: function(val) {
      this.terminals = this.stalls.filter(element => {
        return element.StallId === val.StallId;
        })[0].Terminals;
      },
      
    selectedUser: function() {
      },
  },
  
  methods: {
    ...mapActions('admin', ['postDevices']),
    
    changeStallholder: function (obj) {
      this.editedItem.Stallholder = obj
      this.Stallholder = obj
    },

    changeStall: function (obj) {
      this.editedItem.Stall = obj
      this.selectedStall = obj;
    },
    
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.eventItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
      
    save () {
      let submitItem = {}
      submitItem.StallholderId = this.selectedStallholder.StallholderId
      submitItem.StallId = this.selectedStall.StallId
      submitItem.TerminalId = this.selectedTerminal.TerminalId
      submitItem.UserId = this.selectedUser.TerminalUserId
      submitItem.DeviceId = this.eventItem.DeviceId
      submitItem.MerchantId = this.eventItem.MerchantId
      submitItem.Authorised = this.eventItem.Authorised
      submitItem.EventId = this.globalEventFilter.EventId

      if (this.$refs.form.validate()) {

        try {
          if (this.$refs.form.validate()) {

            let eventSubmitItem =
                {
                    Type: "Device",
                    EventId: submitItem.EventId,
                    Action: this.Action,
                    Devices: [submitItem]
                }
              
            this.postDevices(eventSubmitItem).then(response => {

                iziToast.success({
                    title: 'Device',
                    message: 'Successfully inserted record!',
                    position: 'topRight'
                });

                console.log('save() Response',response)

                this.redirectReload();

            }).catch(err => {
                console.log("ERROR: ",err)

                iziToast.warning({
                    title: 'Device',
                    message: 'Failed to inserted record! Please log in again or contact TSG.',
                    position: 'center'
                });
            })  

          }
        } catch (e) {
          console.log("ERROR: ",e)
        }
        
        this.close()
      }
    },
    
    clickCancel: function () {    
      this.$router.push({name: 'EventManagement'});
      },
      
    redirectReload() { 
      this.$router.push({name: 'EventManagement'});
    }

  }

}
      
</script>